<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-subheader>Lead Certificates</v-subheader>
      <v-list-tile>
        <v-icon left>
          mdi-certificate
        </v-icon>
        <span>LEAD DML100 series</span>
        <v-spacer />
        <span v-if="items.entry === 'true'">
          <v-btn
            :href="printLead()"
            target="_blank"
            fab
            small
            color="info"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </span>
      </v-list-tile>
    </v-card>
    <v-card>
      <v-list-tile>
        <v-icon left>
          mdi-certificate
        </v-icon>
        <span>DMC001 Edisi Semua Menang</span>
        <v-spacer />
        <span v-if="item.entrys === 'true'">
          <v-btn
            :href="printSemuaMenang()"
            target="_blank"
            fab
            small
            color="info"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </span>
      </v-list-tile>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams, createGetUrl } from '@/rest'

const pageTitle = 'Lead Certificates'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'XOX LEAD Program', disabled: false, to: '/lead_menu',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      title: pageTitle,
      items: {},
      item: {},
      loading: false,
    }
  },
  mounted: function () {
    this.getSemuaMenang()
    this.getDealerLead()
  },

  methods: {
    printLead: function () {
      return process.env.NODE_ENV === 'production'
        ? createGetUrl(location.protocol + '//' + location.hostname + '/api/pdf/printLEAD.php')
        : createGetUrl('http://203.115.197.112/api/pdf/printLEAD.php')
    },
    getDealerLead () {
      this.loading = true
      const params = createGetParams({})
      this.$rest.get('getDealerLead.php', params)
        .then((response) => {
          this.items = response.data.items
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
    printSemuaMenang: function () {
      return process.env.NODE_ENV === 'production'
        ? createGetUrl(location.protocol + '//' + location.hostname + '/api/pdf/printSemuaMenang.php')
        : createGetUrl('http://203.115.197.112/api/pdf/printSemuaMenang.php')
    },
    getSemuaMenang () {
      this.loading = true
      const params = createGetParams({})
      this.$rest.get('getDealerSemuaMenang.php', params)
        .then((response) => {
          this.item = response.data.item
          this.loading = true
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
